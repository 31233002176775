import { Table } from 'common'
import columns from './Columns'
import { useNotesJobs } from 'services/notes'
import { AppFilesServices } from 'services'
import moment from 'moment'

interface JobsTableProps {
	id: string | number
	setShowNotesForm: (open: boolean) => void
	setNoteID: (id: string) => void
}

export const JobsTable = ({
	id,
	setShowNotesForm,
	setNoteID,
}: JobsTableProps) => {
	const { data, isLoading } = useNotesJobs(id)
	const { data: files, isLoading: filesLoading } =
		AppFilesServices.useAppFiles()


	const jobFiles = files?.filter((file: any) => file.job_id === id)

	const jobFilesAsNotes = jobFiles?.map((file: any) => {
		return {
			id: file.id,
			fileName: 'Job File',
			fileType: file.file_type,
			fileDescription: file.file_description,
			notes: '',
			fileUrl: file.link,
			createdDate: moment(file.created_at).format('DD/MM/YYYY'),
			uploadedBy: file.uploaded_by
		}
	})
	
	const tableColumns = columns({ setShowNotesForm, setNoteID });
  const columnsToRemove = ['fileType', 'id'];
  const updatedColumns = [...tableColumns.filter(column => !columnsToRemove.includes(column.field))];
	const position = updatedColumns.length - 1
	updatedColumns.splice(position, 0, { header: 'Uploaded by', field: 'uploadedBy',})

	const allData = [...(data ?? []), ...(jobFilesAsNotes ?? [])]

	/*{
			header: 'Created Date',
			field: 'createdDate',
			body: (row: { createdDate: Date }) => {
				return row.createdDate ? moment(row.createdDate, 'DD/MM/YYYY').format('DD/MM/YYYY') : ''
			},
		},
		{
			header: 'File Name',
			field: 'fileName',
		},
		{
			header: 'File Type',
			field: 'fileType',
		},
		{
			header: 'File Description',
			field: 'fileDescription',
		},
		{
			header: 'Notes',
			field: 'notes',
		},
		{
			header: 'File',
			field: 'fileUrl',
			body: (rowData: { fileUrl: string }) => <a href={rowData.fileUrl}>Link</a>,
		},*/

	return (
		<div>
			<Table
				columns={updatedColumns}
				data={allData}
				isLoading={isLoading || filesLoading}
				title="Job Notes & Files"
				setShowNotesForm={setShowNotesForm}
			/>
		</div>
	)
}
