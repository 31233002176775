import { Image, Text, View } from '@react-pdf/renderer'
import * as signature from 'assets/signature.png'
import { styles } from './styles'

export const Signature = () => (
	<View style={{ marginTop: 36 }}>
		<Text style={styles.subTextMargin}>
			I accept this quotation and certify that the above information is true and
			correct. I have read and understand the TERMS AND CONDITIONS OF HIRE
			(overleaf or attached) of Erect Scaffolding Limited which form part of,
			and are intended to be read in conjunction with this Quotation Form and
			agree to be bound by these conditions. I authorise the use of my personal
			information as detailed in the Privacy Act clause therein. I agree that if
			I am a director/shareholder (owning at least 15% of the shares) of the
			Client I shall be personally liable for the performance of the Client’s
			obligations under this contract.
		</Text>
		<Image src={signature.default} />
	</View>
)
