import { Font, StyleSheet } from '@react-pdf/renderer'

Font.register({
	family: 'Open Sans',
	src: 'https://fonts.gstatic.com/s/opensans/v23/mem8YaGs126MiZpBA-UFVZ0e.ttf',
})

export const styles = StyleSheet.create({
	subTextMargin: {
		fontFamily: 'Open Sans',
		fontSize: 7,
		margin: 8,
		textAlign: 'justify',
	},
})
