import { useEffect, useState } from 'react'
import { Dropdown } from 'primereact/dropdown'
import { OptionsForDropdown, numberFormat } from 'utilities'
import {
	IQuoteLine,
	IQuoteZones,
	IRates,
	IQuoteLinesHandlers,
} from 'models/simple_quotes.model'
import { XCircleIcon } from '@heroicons/react/24/outline'
import { Button } from 'primereact/button'
import { ConfirmationDialog } from 'common'
import { quoteLinesFn } from 'services/simple_quotes'

interface QuoteLineProps {
	zones: IQuoteZones[]
	rates: IRates[]
	data: IQuoteLine
	quoteLinesHandlers: IQuoteLinesHandlers
	index: number
}

export const SimpleQuoteLine = ({
	zones,
	rates,
	data,
	index,
	quoteLinesHandlers,
}: QuoteLineProps) => {
	const [dirtyFields, setDirtyFields] = useState({
		erect_and_dismantle: false,
		weekly_hire_fee: false,
	})

	const handleProductLineChange = (
		index: number,
		field: keyof IQuoteLine,
		value: string | number | boolean
	) => {
		quoteLinesHandlers.updateQuoteLine(index, field, value)
	}

	return (
		<tr>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-10">
				<Dropdown
					title="zone_id"
					options={OptionsForDropdown(zones, 'zone_id', 'zone_label')}
					id={`zone_id-${index}`}
					value={data.zone_id}
					onChange={(e) =>
						handleProductLineChange(index, 'zone_id', e.target.value)
					}
					className="w-full h-8 text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-10">
				<Dropdown
					title="Rate"
					options={OptionsForDropdown(rates, 'service', 'service')}
					id={`type-${index}`}
					value={data.type}
					onChange={(e) =>
						handleProductLineChange(index, 'type', e.target.value)
					}
					className="w-full h-8 text-gray-700 bg-white border border-gray-200 rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
				/>
			</td>
			<td className="px-1 py-1 h-8 text-sm bg-white border border-gray-100 whitespace-nowrap w-48">
				<input
					id={`description-${index}`}
					type="text"
					className="h-8 px-2 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					value={data.description}
					onChange={(e) =>
						handleProductLineChange(index, 'description', e.target.value)
					}
				/>
			</td>

			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
				<input
					id={`quantity-${index}`}
					type="number"
					className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					value={data.square_meters}
					onChange={(e) =>
						handleProductLineChange(index, 'square_meters', Number(e.target.value))
					}
				/>
			</td>

			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-24">
				<input
					id={`sqm-${index}`}
					type="number"
					className="h-8 text-sm rounded-md  w-full border border-gray-300 text-gray-900 focus:outline-none text-center"
					value={data.quantity}
					onChange={(e) =>
						handleProductLineChange(index, 'quantity', Number(e.target.value))
					}
				/>
			</td>

			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`erect_and_dismantle-${index}`}
					type="number"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none text-center"
					style={{
						background:
							data.erect_and_dismantle_is_dirty ||
							dirtyFields.erect_and_dismantle
								? '#fff2e2'
								: '',
					}}
					value={Number(data.erect_and_dismantle)}
					onChange={(e) => {
						handleProductLineChange(
							index,
							'erect_and_dismantle',
							parseFloat(e.target.value.replace(/[^\d.-]/g, '')) || 0
						)
						setDirtyFields({ ...dirtyFields, erect_and_dismantle: true })
					}}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`weekly_hire_fee-${index}`}
					type="number"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-600 focus:outline-none"
					style={{
						background:
							data.weekly_hire_fee_is_dirty || dirtyFields.weekly_hire_fee
								? '#fff2e2'
								: '',
					}}
					value={data.weekly_hire_fee}
					onChange={(e) => {
						handleProductLineChange(
							index,
							'weekly_hire_fee',
							Number(e.target.value)
						)
						setDirtyFields({ ...dirtyFields, weekly_hire_fee: true })
					}}
				/>
			</td>
			<td className="px-1 py-1 text-sm bg-white border border-gray-100 whitespace-nowrap w-32">
				<input
					id={`total-${index}`}
					type="text"
					className="h-8 px-2 text-sm rounded-md w-full border border-gray-300 text-gray-500 focus:outline-none"
					value={numberFormat.format(Number(data.total))}
					defaultValue={numberFormat.format(Number(data.total))}
					disabled
				/>
			</td>
			<td className="px-1 py-1 text-tiny bg-white border border-gray-100 whitespace-nowrap w-8">
				<ConfirmationDialog
					icon="danger"
					title="Delete Line Item"
					body="Are you sure you want to delete this item? This action is unrecoverable!"
					triggerButton={
						<button type="button">
							<XCircleIcon
								className="flex-shrink-0 h-4 w-4 text-red-500"
								aria-hidden="true"
							/>
						</button>
					}
					confirmButton={
						<Button
							className="bg-red-600 text-white"
							onClick={async () => quoteLinesHandlers.removeQuoteLine(index)}>
							Delete Line
						</Button>
					}
				/>
			</td>
		</tr>
	)
}
