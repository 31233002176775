import { useState } from 'react'
import { DataTable } from 'primereact/datatable'
import { TimesheetServices, DataTableServices } from 'services'
import { Badge, Container, DataTableHeader, PageHeading } from 'common'
import { Column } from 'primereact/column'
import moment from 'moment'
import { FilterMatchMode } from 'primereact/api'
import { tableFilterMatchModeOptions } from 'services/DataTable'

export const ApprovedTimesheetsPage = () => {
	const [timesheetsSelected, setTimesheetsSelected] = useState(null)
	const { data, isLoading } =
		TimesheetServices.useTimesheetsByStatus('Approved')

	const dataWithHours = data?.map((timesheet: any) => {
		const hours = timesheet.time_off
			? Number(
					calcHours(
						timesheet.time_on,
						timesheet.time_off,
						timesheet.lunch_break
					)
			  )
			: 0
		return { ...timesheet, total_hours: hours }
	})

	const {
		clearFilter,
		filters,
		globalFilterValue,
		setFilters,
		setGlobalFilterValue,
		globalFilterFields,
		dataTableReference,
	} = DataTableServices.useFiltersDataTable({
		initialFilters: [
			{
				filterName: 'status',
				filterInitialValue: 'Approved',
				filterInitialMatchMode: FilterMatchMode.EQUALS,
				filterOptionsMatchOptions: tableFilterMatchModeOptions.equalsOrNot,
				filterOptions: [],
			},
		],
		additionalGlobalFilterFields: ['staff', 'date', 'comments', 'status'],
	})

	const header = DataTableHeader({
		clearFilter,
		globalFilterValue,
		filters,
		setFilters,
		setGlobalFilterValue,
		dataTableReference,
	})

	function calcHours(
		startTime: string,
		finishTime: string,
		lunchBreak: number
	) {
		const start = moment(startTime, 'HH:mm')
		const finish = moment(finishTime, 'HH:mm')

		if (finishTime && finish) {
			const duration = moment.duration(finish.diff(start))
			const hours = duration.asHours() - Number(lunchBreak) / 60
			return hours
		}
		return null
	}

	const calculateTimesheetTotal = (row: {
		staff_id: number
		comments: string
	}) => {
		let total = 0

		if (dataWithHours) {
			for (const timesheet of dataWithHours) {
				if (
					Number(timesheet.staff_id) === row.staff_id &&
					timesheet.status === 'Approved'
				) {
					const hours = timesheet.time_off
						? Number(
								calcHours(
									timesheet.time_on,
									timesheet.time_off,
									timesheet.lunch_break
								)
						  )
						: 0
					total += hours
				}
			}
		}
		return total
	}

	const headerTemplate = (data: {
		staffData: {
			staff_name: string
		}
	}) => (
		<span className="text-gray-900 font-bold">{data.staffData.staff_name}</span>
	)

	const footerTemplate = (data: any) => (
		<>
			<td
				colSpan={7}
				style={{ textAlign: 'right' }}
				className="bg-gray-100 font-normal">
				Total Hours
			</td>
			<td colSpan={1} className="bg-gray-100 font-semibold">
				{calculateTimesheetTotal(data)}
			</td>
		</>
	)

	return (
		<div className="card">
			<PageHeading title="Approved Payroll Timesheets" />
			<Container>
				<DataTable
					ref={dataTableReference}
					value={dataWithHours}
					loading={isLoading}
					paginator
					showGridlines
					rows={100}
					paginatorPosition="top"
					rowGroupMode="subheader"
					groupRowsBy="staffData.staff_name"
					rowGroupHeaderTemplate={headerTemplate}
					rowGroupFooterTemplate={footerTemplate}
					rowsPerPageOptions={[25, 50, 100]}
					dataKey="id"
					filters={filters}
					exportFilename={`Approved Payroll Timesheets Export - ${moment().format('DD-MM-YYYY h_mm_ss a')}`}
					globalFilterFields={globalFilterFields}
					header={header}
					stripedRows
					selection={timesheetsSelected}
					onSelectionChange={(e) => setTimesheetsSelected(e.value as never)}
					emptyMessage="No Timesheets found.">
					<Column selectionMode="multiple" headerStyle={{ width: '3em' }} />
					<Column field="staffData.staff_name" header="Staff" />
					<Column
						header="Date"
						filterField="date"
						field="date"
						style={{ minWidth: '10rem' }}
						filter
						filterType="date"
					/>
					<Column
						header="Actual Start"
						field="actual_start"
						filterField="time_on"
						style={{ minWidth: '6rem' }}
					/>
					<Column
						header="Adjusted Start"
						field="time_on"
						filterField="time_on"
						style={{ minWidth: '6rem' }}
					/>

					<Column
						field="actual_finish"
						header="Actual Finish"
						filterMenuStyle={{ width: '14rem' }}
						style={{ minWidth: '6rem' }}
					/>
					<Column
						field="time_off"
						header="Adjusted Finish"
						filterMenuStyle={{ width: '14rem' }}
						style={{ minWidth: '6rem' }}
					/>
					<Column
						header="Total Hours"
						field="total_hours"
						showFilterMatchModes={false}
						style={{ minWidth: '4rem' }}
					/>
					<Column
						header="Lunch Break"
						field="lunch_break"
						showFilterMatchModes={false}
						style={{ minWidth: '4rem' }}
					/>
					<Column
						field="comments"
						header="Comments"
						bodyClassName="p-text-center"
						style={{ minWidth: '8rem' }}
					/>
					<Column
						header="Status"
						bodyClassName="p-text-center"
						style={{ width: '4rem' }}
						body={(row) => <Badge type={row.status} text={row.status} />}
					/>
					<Column
						header="Approved By"
						field="approved_by"
						bodyClassName="p-text-center"
						style={{ width: '8rem' }}
					/>
					<Column
						header="Exported"
						field="exported"
						bodyClassName="p-text-center"
						style={{ width: '4rem' }}
					/>
				</DataTable>
			</Container>
		</div>
	)
}
