import { Spinner, Table } from 'common'
import moment from 'moment'
import { JobsServices, VisitServices, VisitTimesheetsServices } from 'services'
interface JobQuoteAndActualHoursProps {
	job_id?: number
}
const QuoteAndActualHours = ({ job_id }: JobQuoteAndActualHoursProps) => {
	const { data: tasksData, isLoading: tasksLoading } =
		JobsServices.useJobTask(job_id)
	const { data: visitData, isLoading: visitLoading } =
		VisitServices.useVisitsByJobId(job_id)
	const { data: timesheetsData, isLoading: timesheetsLoading } =
		VisitTimesheetsServices.useVisitTimesheetsByJobId(job_id)

	if (visitLoading || tasksLoading || timesheetsLoading) {
		return <Spinner />
	}

	let staffTimesheets = timesheetsData.map((timesheet: any) => {
		const start_time = moment(timesheet.time_in, 'HH:mm')
		const end_time = moment(timesheet.time_off, 'HH:mm')
		const difference: number = end_time.diff(start_time, 'hours', true) || 0
		return {
			difference: difference,
		}
	})
	
	const totalTimesheetHours = staffTimesheets?.reduce(
		(accumulator: number, item: { difference: number }) => {
			return accumulator + Number(item.difference)
		},
		0
	)

	const totalHoursSum: number = tasksData?.reduce(
		(accumulator: number, item: { total_hours: string }) => {
			return accumulator + Number(item.total_hours)
		},
		0
	)

	const estimatedSQMTotal = tasksData?.reduce(
		(accumulator: number, item: { quoted_square_meters: string }) => {
			return accumulator + Number(item.quoted_square_meters)
		},
		0
	)

	const actualSQMTotal = tasksData?.reduce(
		(accumulator: number, item: { actual_square_meters: string }) => {
			return accumulator + Number(item.actual_square_meters)
		},
		0
	)

	const percentageSQM = (actualSQMTotal / estimatedSQMTotal) * 100
	const percentageHours = (totalTimesheetHours / totalHoursSum) * 100
	const percentageHoursString = percentageHours === Infinity || isNaN(percentageHours) ? '0' : percentageHours.toFixed(2)
	const percentageSQMString = percentageSQM === Infinity || isNaN(percentageSQM) ? '0' : percentageSQM.toFixed(2)

	const columns = [
		{ field: 'type', header: 'Type' },
		{ field: 'estimated', header: 'Quoted' },
		{ field: 'used', header: 'Actual' },
		{ field: 'percent', header: 'Percent of Quoted' },
	]

	return (
		<Table
			columns={columns}
			data={[
				{ type: 'Hours', estimated: totalHoursSum, used: totalTimesheetHours, percent: percentageHoursString},
				{ type: 'Square Meters', estimated: estimatedSQMTotal, used: actualSQMTotal, percent: percentageSQMString},
			]}
			isLoading={visitLoading || tasksLoading || timesheetsLoading}
			title="Quoted hours vs. Actual hours"
			disableButtons
		/>
	)
}

export default QuoteAndActualHours
