import { Table } from 'common'
import { columns } from './Columns'
import { useAppFilesTags } from 'services/appfiles'

interface ScaffoldTableProps {
	id: string | number
}

export const ScaffoldInspectionTable = ({ id }: ScaffoldTableProps) => {
	const { data, isLoading } = useAppFilesTags(id)
	const columnsToRemove = ['file_description'];
  const updatedColumns = [
    ...columns.filter(column => !columnsToRemove.includes(column.field)),
    {
      header: 'Uploaded by',
      field: 'uploaded_by',
    },
  ];

	return (
		<div>
			<Table
				columns={updatedColumns}
				data={data}
				isLoading={isLoading}
				title="Scaffold Inspection Files"
			/>
		</div>
	)
}
