import {
	CheckIcon,
	XCircleIcon,
	DocumentCheckIcon,
	ExclamationCircleIcon,
	XMarkIcon,
} from '@heroicons/react/24/outline'
import { useEffect, useState } from 'react'
import { Input, TextArea, Button, ConfirmationDialog } from 'common'
import { QuoteData } from 'models'
import { useUploadQuote } from 'services/quotes/UploadQuote'
import { useSendQuote, useSendQuoteTemplate } from 'services/quotes/sendQuote'
import { AppRoutes } from 'config'
import { QuotesMainTable } from 'pages/Quotes'
interface Props {
	quoteId: number
	emailStatus: QuoteEmailStatuses
	quote: QuoteData
	onlineAccept: boolean
}

enum QuoteEmailStatuses {
	readyToSend = 'Ready to Send',
	sent = 'Sent',
	delivered = 'Delivered',
	undelivered = 'Undelivered',
	opened = 'Opened',
	linkClicked = 'Link Clicked',
	customerAccepted = 'Customer Accepted',
	customerReferred = 'Customer Queried',
	customerDeclined = 'Customer Declined',
}

export const EmailStatus = ({
	quoteId,
	emailStatus,
	quote,
	onlineAccept,
}: Props) => {
	const [isLoading, setIsLoading] = useState(false)
	const { uploadQuote } = useUploadQuote()
	const { sendEmail, markAsPending } = useSendQuote()
	const sendEmailTemplate = useSendQuoteTemplate()

	const clientContact = quote.client_contact ? quote.contactData.name : ''

	const [emailState, setEmailState] = useState({
		subject: `Quote ${quote.quote_num}`,
		body: `Hi ${clientContact}


Thank you for the opportunity to be involved with this project. Please see our quote attached, which is based on the information provided to us and/or a site visit.


Client Name: ${quote.clientData.client_name}
Job Reference: ${quote.street}
Quote/Job #: ${quote.quote_num}
Attention of: ${clientContact}


To accept our quote, please either reply to this email with your preferred start date and purchase order number (if applicable); OR use this link to review and accept our quote.


If you have any questions or would like to discuss further, please don't hesitate to get in touch.


We look forward to working with you on this project.
Thanks again,

`,
	})
	useEffect(() => {
		if (onlineAccept) {
			setEmailState({
				...emailState,
				subject: `ErectNZ Quote #${quote.quote_num}`,
			})
		}
	}, [])

	const newStatus =
		emailStatus === QuoteEmailStatuses.readyToSend
			? QuoteEmailStatuses.sent
			: QuoteEmailStatuses.readyToSend

	const handleInputChange = (
		event:
			| React.ChangeEvent<HTMLInputElement>
			| React.ChangeEvent<HTMLTextAreaElement>
	) => {
		const { id, value } = event.target
		setEmailState({
			...emailState,
			[id]: value,
		})
	}
	if (!onlineAccept) {
		return (
			<ConfirmationDialog
				isDone={isLoading}
				icon="info"
				title="Send Quote"
				body={`Are you sure you want to mark this quote as ${newStatus} ? ${
					newStatus === QuoteEmailStatuses.sent
						? 'This action will send a quote to the client contact.'
						: ''
				}`}
				triggerButton={
					<button
						type="button"
						disabled={
							quote.status === 'Approved' || quote.status === 'Declined'
						}
						id={`${quoteId}`}
						className="ml-3 inline-flex items-center text-sm font-medium focus:outline-none hover:text-green-400">
						{emailStatus === QuoteEmailStatuses.readyToSend && (
							<CheckIcon
								className="-ml-1.5 mr-2 h-5 w-5 text-green-400"
								aria-hidden="true"
							/>
						)}
						{emailStatus === QuoteEmailStatuses.sent && (
							<XCircleIcon
								className={
									quote.status !== 'Approved' && quote.status !== 'Rejected'
										? '-ml-1.5 mr-2 h-5 w-5 text-red-400'
										: '-ml-1.5 mr-2 h-5 w-5 text-red-100'
								}
								aria-hidden="true"
							/>
						)}

						<p
							className={
								quote.status !== 'Approved' && quote.status !== 'Rejected'
									? 'text-gray-600'
									: 'text-gray-300'
							}>
							{emailStatus}
						</p>
					</button>
				}
				confirmButton={
					<Button
						isLoading={isLoading}
						variant="approve"
						size="sm"
						onClick={async () => {
							setIsLoading(true)
							if (!emailState.body || !emailState.subject) {
								return
							}

							if (newStatus === QuoteEmailStatuses.sent) {
								const urlQuote = await uploadQuote(quoteId)

								// eslint-disable-next-line @typescript-eslint/no-unused-vars
								let htmlText = ''
								const messageTextArray = emailState.body.split('\n')

								messageTextArray.forEach((line) => {
									htmlText += `<p>${line}</p>`
								})

								htmlText += `<p><a href="${urlQuote}">Click here to view quote</a></p>`

								const emailData = {
									subject: emailState.subject,
									email: quote.contactData.email,
									body: htmlText,
									quoteId: quoteId,
								}

								await sendEmail(emailData)
							} else {
								await markAsPending(quoteId)
							}
							setIsLoading(false)
						}}>
						Mark as {newStatus}
					</Button>
				}>
				<>
					{newStatus === QuoteEmailStatuses.sent && (
						<>
							<div className="flex">
								<div className="w-1/2">
									<Input
										title="Subject"
										id="subject"
										type="text"
										value={emailState.subject}
										handleChange={handleInputChange}
										error={!emailState.subject ? 'Subject Is Requied' : ''}
										placeholder={''}
										handleBlur={() => null}
									/>
								</div>
							</div>
							<TextArea
								title="Body Text"
								id="body"
								type="text"
								value={emailState.body}
								handleChange={handleInputChange}
								rows={10}
								error={!emailState.body ? 'Body Is Requied' : ''}
							/>
						</>
					)}
				</>
			</ConfirmationDialog>
		)
	} else {
		return (
			<ConfirmationDialog
				isDone={isLoading}
				icon="info"
				title={
					newStatus === QuoteEmailStatuses.sent
						? 'Email to Customer'
						: 'Revert to pending'
				}
				body={
					newStatus === QuoteEmailStatuses.sent
						? 'This action will email the customer a link to accept the quote.'
						: 'This action will revert this quote to pending state to allow resending'
				}
				triggerButton={
					<button
						type="button"
						disabled={
							quote.status === 'Approved' ||
							quote.status === 'Declined' ||
							emailStatus === QuoteEmailStatuses.customerAccepted ||
							emailStatus === QuoteEmailStatuses.customerReferred ||
							emailStatus === QuoteEmailStatuses.customerDeclined
						}
						id={`${quoteId}`}
						className="ml-3 inline-flex items-center text-sm font-medium focus:outline-none hover:text-green-400">
						{emailStatus === QuoteEmailStatuses.readyToSend && (
							<CheckIcon
								className="-ml-1.5 mr-2 h-5 w-5 text-green-400"
								aria-hidden="true"
							/>
						)}
						{emailStatus === QuoteEmailStatuses.sent && (
							<XCircleIcon
								className={
									quote.status !== 'Approved' && quote.status !== 'Rejected'
										? '-ml-1.5 mr-2 h-5 w-5 text-red-400'
										: '-ml-1.5 mr-2 h-5 w-5 text-red-100'
								}
								aria-hidden="true"
							/>
						)}
						{emailStatus === QuoteEmailStatuses.customerAccepted && (
							<DocumentCheckIcon
								className="-ml-1.5 mr-2 h-5 w-5 text-green-500"
								aria-hidden="true"
							/>
						)}
						{emailStatus === QuoteEmailStatuses.customerReferred && (
							<ExclamationCircleIcon
								className="-ml-1.5 mr-2 h-5 w-5 text-orange-500"
								aria-hidden="true"
							/>
						)}
						{emailStatus === QuoteEmailStatuses.customerDeclined && (
							<XCircleIcon
								className="-ml-1.5 mr-2 h-5 w-5 text-red-500"
								aria-hidden="true"
							/>
						)}
						<p
							className={
								quote.status !== 'Approved' && quote.status !== 'Rejected'
									? 'text-gray-600'
									: 'text-gray-300'
							}>
							{emailStatus}
						</p>
					</button>
				}
				confirmButton={
					<Button
						isLoading={isLoading}
						variant="approve"
						size="sm"
						onClick={async () => {
							setIsLoading(true)
							if (!emailState.body || !emailState.subject) {
								return
							}

							if (newStatus === QuoteEmailStatuses.sent) {
								const emailData = {
									subject: emailState.subject,
									email: [quote.contactData.email, quote.estimatorData.email],
									quoteId: quote.id,
									customer_name: quote.contactData.name,
								}

								await sendEmailTemplate(emailData)
							} else {
								await markAsPending(quoteId)
							}
							setIsLoading(false)
						}}>
						{newStatus === QuoteEmailStatuses.sent
							? 'Email to Customer'
							: 'Revert to pending'}
					</Button>
				}>
				<>
					{newStatus === QuoteEmailStatuses.sent && (
						<>
							<div className="flex">
								<div className="w-full">
									<Input
										title="Subject"
										id="subject"
										type="text"
										value={emailState.subject}
										handleChange={handleInputChange}
										error={!emailState.subject ? 'Subject Is Requied' : ''}
										placeholder={''}
										handleBlur={() => null}
									/>
								</div>
							</div>
						</>
					)}
				</>
			</ConfirmationDialog>
		)
	}
}
